import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-how-to-translate-kernel-content",
      "style": {
        "position": "relative"
      }
    }}>{`⚙️ How to Translate Kernel Content`}<a parentName="h1" {...{
        "href": "#%EF%B8%8F-how-to-translate-kernel-content",
        "aria-label": "️ how to translate kernel content permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Translations can present a challenge. Our philosophy is that "content creators write the website", so the content folder therefore decides how the site is rendered, including translated content. This means, when you start to translate Kernel into your language, you'll need to create a brand new folder under the content directory and name it appropriately, i.e. `}<strong parentName="p">{`en`}</strong>{` for English, `}<strong parentName="p">{`es`}</strong>{` for Spanish, `}<strong parentName="p">{`fr`}</strong>{` for French, `}<strong parentName="p">{`ko`}</strong>{` for Korean etc.`}</p>
    <p>{`We show translations on a per page basis, so as you translate the content, you'll need to then set the `}<inlineCode parentName="p">{`hideLanguageSelector`}</inlineCode>{` field in your frontmatter to `}<strong parentName="p">{`false`}</strong>{`, or simply do not include it (as it renders by default). This is cool because you can translate one page at a time instead of doing the whole project and then waiting months for reviews.`}</p>
    <p>{`Because the content folder defines how the website renders, we need to keep a separate file for UI elements that are not found in any of the `}<strong parentName="p">{`mdx`}</strong>{` files - like the text in the search box, or in the footer etc. Follow the structure in `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/kernel-v2/blob/r2d/content/en/UI.json"
      }}>{`this file`}</a>{` and make sure that each element there is translated. `}</p>
    <p>{`Adding a new language in that file and a new directory in the `}<strong parentName="p">{`content`}</strong>{` folder, named appropriately, should result in your language automatically appearing in the language selector for the pages you have translated.`}</p>
    <h2 {...{
      "id": "translator-responsibilities",
      "style": {
        "position": "relative"
      }
    }}>{`Translator Responsibilities`}<a parentName="h2" {...{
        "href": "#translator-responsibilities",
        "aria-label": "translator responsibilities permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The role of a translator is to create the initial translation from the original English documents.`}</p>
    <p>{`Your responsibility is to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Translate as accurately as possible:`}</strong>{` All languages have their nuances, so there is some flexibility when changing the text to be more readable in a new language. Communicate with the rest of the team early and often in Slack. Don’t be afraid to ask others for advice.`}</li>
      <li parentName="ul"><strong parentName="li">{`Decide which unique terms to translate:`}</strong>{` If there are any terms that do not have a direct equivalent in your language, please make sure to reach out to us in Slack and discuss the various options and their impact on the meaning.`}</li>
      <li parentName="ul"><strong parentName="li">{`Provide accurate spelling and grammar.`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Be consistent:`}</strong>{` Ensure terms are translated uniformly across all documents.`}</li>
      <li parentName="ul"><strong parentName="li">{`Maintain simplicity:`}</strong>{` Writing should be plain, simple, and accessible. Maintain contact with other translators as they work on documents. Be sure to agree on translated terms and other conventions.`}</li>
      <li parentName="ul"><strong parentName="li">{`Format consistently:`}</strong>{` Ensure that translated documents retain original formatting.`}</li>
    </ul>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best Practices`}<a parentName="h2" {...{
        "href": "#best-practices",
        "aria-label": "best practices permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use Markdown:`}</strong>{` All documents are formatted in Markdown. Use the `}<a parentName="li" {...{
          "href": "/guiding/recipes"
        }}>{`cheatsheet`}</a>{` to help you if you get lost.`}</li>
      <li parentName="ul"><strong parentName="li">{`Maintain Original Formatting.`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Use a Markdown Editor:`}</strong>{` Editors like `}<a parentName="li" {...{
          "href": "https://hackmd.io/"
        }}>{`hackmd.io`}</a>{` or `}<a parentName="li" {...{
          "href": "https://code.visualstudio.com/"
        }}>{`VScode`}</a>{` make working with Markdown more convenient.`}</li>
      <li parentName="ul"><strong parentName="li">{`Keep hyperlinks as they are:`}</strong>{` Do not translate the actual URL. The editors will handle any necessary changes to links.`}</li>
      <li parentName="ul"><strong parentName="li">{`Take notes:`}</strong>{` of the specific things you would like editors to keep in mind.`}</li>
      <li parentName="ul"><strong parentName="li">{`Editor Tools:`}</strong>{` Feel free to use any software that helps find translation errors and improve writing. We encourage using tools that assist workflow but copying directly from a program will disqualify you from receiving a bounty and from future assignments. Please use them as an aide and not a substitute for your work.`}</li>
      <li parentName="ul"><strong parentName="li">{`Communicate early and often`}</strong>{`: Join our `}<a parentName="li" {...{
          "href": "https://kernel-community.slack.com"
        }}>{`Slack`}</a>{`. We encourage reaching out to translators and reviewers for guidance.`}</li>
    </ul>
    <h2 {...{
      "id": "reviewer-responsibilities",
      "style": {
        "position": "relative"
      }
    }}>{`Reviewer Responsibilities`}<a parentName="h2" {...{
        "href": "#reviewer-responsibilities",
        "aria-label": "reviewer responsibilities permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The reviewer’s role is to ensure that translated documents meet both our formatting and writing style standards. Your responsibilities consist of proofreading documents for:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Spelling and Grammar:`}</strong>{` Correct mistakes and typos if any are found.`}</li>
      <li parentName="ul"><strong parentName="li">{`Language:`}</strong>{` Ensure that the writing style is in-line with Kernel terminology and best practices. For example, some languages translate the word "blockchain" while others keep the English spelling.`}</li>
      <li parentName="ul"><strong parentName="li">{`Consistency:`}</strong>{` Ensure terms are translated uniformly across all documents.`}</li>
      <li parentName="ul"><strong parentName="li">{`Simplicity:`}</strong>{` Our aim is to write plainly and simply. Maintain contact with translators as they work on documents. Be sure to agree on translated terms and other conventions.`}</li>
      <li parentName="ul"><strong parentName="li">{`Formatting:`}</strong>{` Ensure that translated documents retain the original formatting.`}</li>
      <li parentName="ul"><strong parentName="li">{`Adherence to the Kernel Style:`}</strong>{` A simple style must be maintained throughout all documents.`}</li>
      <li parentName="ul"><strong parentName="li">{`Hyperlinks:`}</strong>{` Give extra attention to this task. Hyperlinks allow users to explore other important resources. Ensure all links are functioning properly and included where necessary. Hyperlinks fall into two general categories:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Links Between Translated Documents:`}</strong>{` If possible, ensure that the link goes to a page which has also been translated into that same language.`}</li>
          <li parentName="ul"><strong parentName="li">{`Links to Other Pages:`}</strong>{` Try to source a native language version for the link. For example, if a link leads to a Wikipedia page, it’s likely that a version of this page exists in the native language.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      